import React, { PureComponent, useState, useCallback, useEffect } from "react";

import Link from "../LinkWithTransitionLock"
import InViewObserver from "../InViewObserver"

class Page extends PureComponent {

  constructor(props) {
    super(props);
  }
  
  componentDidMount() {

    InViewObserver()

  }

  componentWillUnmount() {}

  render() {
    return (

      <div className="content_wrap ">

        <div className="page_header">
          <h2 className="sub_title">社宅公共藝術咖啡桌 / 第一輪</h2>
          <h1 className="main_title">#回饋與討論</h1>
        </div>

        <article className="page_content blog_single_post_content">

          <section className="row_area_s">
            <div className="column_5">
              <div className="context">
                <h4>殷寶寧</h4>
                <p>殷寶寧綜合剛剛四組討論的概念，認為有兩個共通的關鍵字，第一是對永續性的關切，第二是開放性。兩個議題緊密相連，連帶牽涉到其他獨立的問題。</p>
                <p>關於永續，她以九二一校園重建為例，民眾普遍認為蓋新的就是好，而非理性思考其優缺點，然而新的事物經時間流逝會變舊。當時政府只編興建經費，沒有維修經費。當校舍變舊、出現問題時，校長需要爭取一筆新預算，而這筆預算可能就是把舊校舍拆掉，因而陷入惡性循環。</p>
                <p>殷寶寧認為討論創造機制，讓永續成為可能之前，需要先問：永續概念是什麼？包含了什麼？</p>
                <p>她提出了三個面向，第一面向是機制永續，即上一輪提到的法人機構；第二是作品永續，無論其形式為物質或操作型，挑戰的是政府採購財產列管、報廢、定期檢查的狀況，因此編列預算的思考，要朝向經營而非資本模式；第三面向是人的加入，能否由公共藝術預算編列讓人進來維繫這個機制。</p>
                <p>另外，除了上面三個面向，更該考慮到住民的永續。社宅居民是三年一輪的流動狀態，該思考如何尋找切入點，去了解一輪接一輪居住進來的住民，動員他們的情感，創造認同。</p>
                <p>談到處理認同，四年前提出社會住宅時，社會的氛圍並不友善，認為未來的住戶是一群被標籤化的住民，近年有提出一些策略來對應，如青銀共創、青創等。另一方面，台灣新住民的人口越來越龐大，按內政部統計含外島外配人數共170多萬。討論城市認同時，如何看待這群和我們歷史、文化背景相對差異龐大的群體？回應到前面的問題：永續的概念是什麼？呈現他們獨特的文化？或朝更融合的狀態發展？</p>
                <p>傳承創新組提出，透過公共藝術建構出社區地圖，累積屬於在地的認同。又或者城市策略組提到一種動態的模式提醒我們，面對同樣的地區、流動的住民，其文化內涵是否不變？</p>
                <p>綜合上述社宅未來將面對的各項議題，殷寶寧提出公民社會組所說：我們要相信人，相信藝術的力量非常的重要。藝術強調的是內在的情感面，它的浪漫能打動人心是因為它來自於人的情感。如何建立在動員情感的面向又可以確保機制的永續，便是我們要繼續探討的課題。 </p>

                <h4>林靜娟</h4>
                <p>林靜娟老師舉建築與社宅設計的準則制定以及設計策略實踐為例，認為公共藝術也可以建立這樣的思考。有些基本問題需要先釐清：住戶具有不確定性與流動性，因此更需要界定某些普遍性準則，在那個基礎上，去回應真實情況裡的特殊性。</p>

                <p>林老師提醒：</p>
                <p>第一有機會的話，要回應真實的社宅條件。</p>
                <p>第二要擴大藝術的領域和形式限制：</p>
                <p>以台北戲曲學院公共藝術為例，公共藝術理念提出可巡迴表演的車，在文化部審議時，因為不是固著式的資產或藝術品，而產生了很大的爭論。</p>
                <p>臺北市政府包容力較寬，但大部分仍都是雕塑、繪畫類的視覺藝術，長久以來，都是如此，公共藝術硬體放五年之後，造成很多環境問題。令人感嘆，每年那麼多公共藝術投資，到底改變了什麼?</p>
                <p>第三什麼才是公共藝術，必須要形成一個清楚的論述，各位剛才談到很多概念，有助於整理形成我們的論述。他不只是創作而是行動，我們要的是什麼，什麼才能真的改變我們人和社會關係，公共藝術論述要更具體的思考這個問題。</p>
                <p>第四 永續與營利</p>
                <p>回應各組對永續與營利的討論，最近常聽到的名詞是社區設計或地方創生，林老師舉了一個早期例子來思考：</p>
                <p>英國20世紀初，藝術運動者進行社造的方式，是開發產品創造經濟價值，讓弱勢社區的居民販賣商品，讓他們有工作、提升基本生活。除了商品包裝和營利，另一個方式是社區管委會以在地職缺雇用進入社區的弱勢族群，真實地給予他們更好的經濟生活與條件。社宅公共藝術能否做到這些事？有思考的空間。</p>
                <p>目前大部分公共藝術的執行方法，就是一次投入一筆經費，短時間執行完畢，過五年、十年毀壞再移除。這種一次性的做法已經過時。</p>

                <h5>小結:</h5>
                <p>藝術不是創作而已，而是可以改變、培養，甚至是形塑台北人的行動。如果我們可以突破視覺藝術的魔咒，才有更大的發揮空間，很多國外的美術館把公共藝術變成幼兒的藝術教育、培養對藝術的鑑賞的「行動」。</p>
                <p>當思及對藝術的定義，林老師想起這一句話：那是對真實生活的另一種眼光，創造我們去看待生活的新角度。公共藝術可以保持這個初衷，無須侷限於硬體形式，一次性的大筆硬體設置投資，已經過時。</p>
              </div>
            </div>
          </section>

        </article>

        <div className="page_footer">
          <Link to="/blog/post_2_6" className="next_page">
            <div className="next_title">#傳承創新</div>
            <div className="next_owner">社宅公共藝術咖啡桌 / 第二輪</div>
          </Link>
        </div>

      </div>

    );
  }
}


export default Page
